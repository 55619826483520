import React, { useState } from 'react';

const AddCardForm = ({ onAddCard, onCancel, availableInputs, deployment }) => {
  const [inputs, setInputs] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleCancel = (e) => {
    e.preventDefault();
    onCancel();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isLoading) return; // Prevent multiple submissions
    setIsLoading(true);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', `/crewai_plus/management_ui/${deployment.id}/kickoff`, false);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('X-CSRF-Token', document.querySelector('meta[name="csrf-token"]').content);

    try {
      xhr.send(JSON.stringify({ inputs: inputs }));

      if (xhr.status !== 200) {
        throw new Error(`HTTP error! status: ${xhr.status}`);
      }

      JSON.parse(xhr.responseText); // Parse the response, but we're not using it
      onAddCard(inputs);
    } catch (error) {
      console.error('Error kicking off crew:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mt-2">
      <div className="bg-white rounded-lg shadow-md p-4">
        <h2 className="text-sm m-0 mb-4 text-gray-800">Trigger new Crew</h2>
        <div className="space-y-4">
          {availableInputs.map((input, index) => (
            <div key={index} className="flex flex-col">
              <label htmlFor={input} className="mb-1 font-medium text-gray-700 text-xs">
                {input.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
              </label>
              <input
                type="text"
                id={input}
                name={input}
                value={inputs[input] || ''}
                onChange={(e) => setInputs({ ...inputs, [input]: e.target.value })}
                className="text-xs border border-gray-200 rounded-md px-3 py-1 focus:outline-none focus:ring-2 focus:ring-primary-color focus:border-primary-color"
                placeholder={`Enter ${input.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}`}
                required
              />
            </div>
          ))}
        </div>
        <div className="mt-4 flex justify-end space-x-2">
          <button
            onClick={handleCancel}
            className="w-1/2 bg-gray-300 hover:bg-gray-400 text-gray-800 py-1 px-3 rounded text-sm transition-colors duration-200"
          >
            Cancel
          </button>
          <button
            disabled={isLoading}
            className="w-1/2 bg-primary-color hover:bg-primary-color-dark text-white py-1 px-3 rounded text-sm transition-colors duration-200"
          >
            {isLoading ? 'Creating...' : 'Trigger Crew'}
          </button>
        </div>
      </div>
    </form>
  );
};

export default AddCardForm;
